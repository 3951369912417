import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'
import { ReactSVG } from 'react-svg'
import uniq from 'lodash/uniq'

import SEO from '@/helpers/SEOHelper.js'

import Image from '@/components/Image'
import Layout from '@/components/Layout'
import Attribute from '@/components/Attribute'
import AirtableIntegration from '@/components/Airtable/AirtableIntegration'
import SubProject from '@/components/Projects/Subproject'

export const DeliverableTemplate = ({ project, projects, content }) => {
  const details = project.details

  function sortProjectsByIds(projects, ids) {
    let result = []
    ids.forEach((id) => {
      let project = projects.find((item) => item.node.frontmatter.id === id)
      if (project) result.push(project)
    })
    return result
  }

  let promoted = projects
  const events = projects && project.events ? projects : []

  promoted = uniq([...events, ...promoted])

  return (
    <>
      <section className='DeliverablePage'>
        <div className='container'>
          <button
            onClick={() => window.history.go(-1)}
            className='DeliverablePage__link'>
            <ReactSVG src='/img/arrow.svg'></ReactSVG>
            <span>Back</span>
          </button>
        </div>
        <div className='container pt-32 pb-32'>
          <div className='DeliverablePage__hero'>
            <div className='cols cols--15'>
              <div className='col col-50'>
                <div className='DeliverablePage__details'>
                  <h1
                    className='mb-40'
                    data-aos='fade-right'
                    data-aos-delay='50'>
                    {project.title}
                  </h1>
                  <div data-aos='fade-right' data-aos-delay='100'>
                    {ReactHtmlParser(content)}
                  </div>
                </div>
              </div>
              <div className='col col-50'>
                <div
                  className='attributes'
                  data-aos='fade-left'
                  data-aos-delay='150'>
                  {project &&
                    Object.keys(details).map((key) => (
                      <Attribute
                        key={key}
                        name={key}
                        value={details[key]}
                        light={true}></Attribute>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {project.airtable?.link && (
          <AirtableIntegration airtable={project.airtable} />
        )}
        <div className='container mt-64'>
          <div className='DeliverablePage__gallery'>
            {project.video?.active ? (
              <video
                src={project.video.url}
                className='featured-video'
                controls
                muted
                loop
                autoPlay></video>
            ) : project.thumbnail && project.thumbnail.image ? (
              <Image
                className='gallery-item'
                author={project.thumbnail.author}
                image={project.thumbnail.image}
                aos='fade-up'
                delay={0}
              />
            ) : (
              ''
            )}
            {project.gallery &&
              project.gallery.map((item, i) => (
                <Image
                  className='gallery-item'
                  author={item.author}
                  key={i}
                  image={item.image}
                  aos='fade-up'
                  delay={50 * i}
                />
              ))}
          </div>
        </div>

        <div className='container'>
          <div className='ProjectPage__children'>
            {promoted &&
              promoted.map(({ node: project }) => (
                <SubProject
                  key={project.id}
                  project={project}
                  content={project.excerpt}></SubProject>
              ))}
          </div>
        </div>
      </section>
    </>
  )
}

const DeliverablePage = ({ data }) => {
  const { frontmatter: project } = data.project
  const { edges: projects } = data.projects
  const content = data.project.html
  const seo = project.seo

  const description = seo ? seo.description : ''
  const title = seo ? seo.title : project.title

  return (
    <Layout>
      <Helmet>
        <meta name='description' content={SEO.description(description)} />
        <meta property='og:title' content={SEO.title(title)} />
        <meta
          property='og:description'
          content={SEO.description(description)}
        />
        <title>{SEO.title(title)}</title>

        {project.secret && <meta name='robots' content='noindex,nofollow' />}
      </Helmet>
      <DeliverableTemplate
        project={project}
        projects={projects}
        content={content}
      />
    </Layout>
  )
}

export default DeliverablePage

export const deliverablePageQuery = graphql`
  query DeliverablePage(
    $id: String!
    $projects: [String!]
    $isSecret: Boolean!
  ) {
    project: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        id
        title
        airtable {
          link
          view
          sort {
            field
            direction
          }
        }
        parent
        thumbnail {
          author
          image {
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 45
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        details {
          client
          purpose
          type
          roles
          size
          when
          where
          established
          regularity
          initiatives
          website
        }
        gallery {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 45
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          author
        }
        events
        seo {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
    projects: allMarkdownRemark(
      filter: {
        frontmatter: {
          id: { in: $projects }
          type: { eq: "project" }
          secret: { eq: $isSecret }
        }
      }
      sort: { fields: frontmatter___calendar___startdate, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt(pruneLength: 120, format: HTML)
          frontmatter {
            id
            date
            title
            layout
            secret
            parent
            video {
              active
              url
            }
            thumbnail {
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 1000
                    quality: 45
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              author
            }
            details {
              client
              purpose
              type
              roles
              size
              when
              where
              established
              regularity
              initiatives
              website
            }
            calendar {
              show
              startdate
              enddate
              isOngoing
            }
          }
        }
      }
    }
  }
`
